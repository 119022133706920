@import 'custom/_variables.scss';
@import 'bootstrap/bootstrap';
@import 'syntax-highlight';

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.9);
}

a:hover {
    background-color: $primary;
    color: #FFF;
}

a:focus {
    background-color: $primary;
    color: #FFF;
    text-decoration: underline;
}

a.icon-link:hover, a.icon-link:focus {
    color: $primary;
    background-color: #f8f9fa;
    outline: 3px solid black;
}

a.image-link:hover, a.image-link:focus {
    display: inline-block;
    outline: 3px solid black;
}

.badge-tag {
    color: $primary;
    background-color: #FFF;
    border: 1px solid $primary;
}

.badge-tag[href]:hover {
    color: #FFF;
    background-color: $primary;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
